<template>
  <svg viewBox="0 0 24 25">
    <path
      d="M9.366 11.182C10.3043 12.8305 11.6695 14.1957 13.318 15.134L14.202 13.896C14.3442 13.6969 14.5543 13.5569 14.7928 13.5023C15.0313 13.4478 15.2814 13.4825 15.496 13.6C16.9103 14.3729 18.4722 14.8378 20.079 14.964C20.3298 14.9839 20.5638 15.0975 20.7345 15.2823C20.9052 15.4671 21 15.7094 21 15.961V20.423C21.0001 20.6706 20.9083 20.9094 20.7424 21.0932C20.5765 21.277 20.3483 21.3927 20.102 21.418C19.5698 21.4729 19.0351 21.5003 18.5 21.5C9.94 21.5 3 14.56 3 6C3 5.462 3.027 4.928 3.082 4.398C3.10725 4.1517 3.22298 3.92352 3.40679 3.75763C3.5906 3.59175 3.82941 3.49995 4.077 3.5H8.539C8.79056 3.49997 9.0329 3.59475 9.21768 3.76545C9.40247 3.93615 9.51613 4.17022 9.536 4.421C9.66222 6.02779 10.1271 7.58968 10.9 9.004C11.0175 9.21856 11.0522 9.46874 10.9977 9.7072C10.9431 9.94565 10.8031 10.1558 10.604 10.298L9.366 11.182ZM6.844 10.525L8.744 9.168C8.20478 8.00409 7.83535 6.76884 7.647 5.5H5.01C5.004 5.666 5.001 5.833 5.001 6C5 13.456 11.044 19.5 18.5 19.5C18.667 19.5 18.834 19.497 19 19.49V16.853C17.7312 16.6646 16.4959 16.2952 15.332 15.756L13.975 17.656C13.4287 17.4437 12.898 17.1931 12.387 16.906L12.329 16.873C10.3676 15.7567 8.74328 14.1324 7.627 12.171L7.594 12.113C7.30691 11.602 7.05628 11.0713 6.844 10.525Z"
    />
  </svg>
</template>

<script>
export default {
  name: "PhoneIcon",
};
</script>
